import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { useSpring, animated } from "react-spring"
import Slider from "react-animated-slider"
import "react-animated-slider/build/horizontal.css"

const Card = styled.article`
  position: absolute;
  overflow: hidden;
  background-size: cover;
  height: 60vh;
  color: #ffffff;
  padding: 50px;
  width: 100%;
  @media (max-width: 1500px) {
    width: 90%;
  }
  display: grid;
  grid-template-columns: 100px 1fr 100px;
  align-items: center;
  justify-items: center;
  grid-column-gap: 30px;
  margin: 100px auto;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    transition: all 2s;
    z-index: -1;
    background-image: url(${({ background }) => background});
    background-size: cover;
  }
  &.moveRight {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 2500px;
      transition: all 2s;
      z-index: -1;
      background-image: url(${({ background }) => background});
      background-size: cover;
    }
  }
  &.moveLeft {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: -2500px;
      transition: all 2s;
      z-index: -1;
      background-image: url(${({ background }) => background});
      background-size: cover;
    }
  }
  button {
    background: #ffffff;
    border: none;
    padding: 20px;
    border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
  }
  .content {
    justify-self: self-start;
    align-self: self-start;
    p {
      background: #d3d3d3d1;
      padding: 0 20px;
      color: #000000;
    }
  }
`

const CardWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 80%;
  display: block;
  height: 800px;
  margin: 0 auto;
`

const SliderWrapper = styled.div`
  .slider {
    height: 700px;
    overflow: hidden;
    border: 4px solid #000;
    width: 100%;
    @media (min-width: 768px) {
      margin: 50px auto 0 auto;
    }
    .center {
      @media (min-width: 768px) {
        padding: 30px 100px;
      }
    }
    p {
      color: #000;
      width: 75%;
      // background: lightgrey;
      padding: 0 20px;
      font-family: "Andika", sans-serif;
      font-size: 22px;
      line-height: 1.4;
    }
    h1 {
      text-align: left;
      color: #000;
      font-size: 32px;
      @media (min-width: 768px) {
        font-size: 32px;
      }
      @media (min-width: 1280px) {
        font-size: 32px;
      }
    }

    .nextButton,
    .previousButton {
      background: #000;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: grid;
      align-items: center;
      justify-items: center;
      svg {
        polygon {
          fill: #ffffff;
        }
      }
    }
  }
`

const Bg = styled.div`
  width: 100%;
  background-size: cover;
  background-position: center center;
  // background-image: url(${({ background }) => background});
  div.center{
    color: #000;
    display:grid;
    grid-template-columns: 1fr 75%;
    img{
      object-fit:cover;
      width: 700px;
      align-items:center;
      justify-items:center;
    }
  }
`

const TitlePage = styled.div`
  width: 50%;
  margin: 0 auto;
  h1{
    text-align: center !important;
    color: #fff !important;
    font-size: 62px !important;
    margin: 0;
  }
  height: 75vh;
  background-size: cover;
  background-position: center center;
  background-image: url(${({ bg }) => bg});
`

//comment

const SlideStory = () => {
  const [current, setCurrent] = useState(1)

  // const [theRest, theRest] = useState(false);
  const trans = x => `translateX(${x}px)`

  const [props, set] = useSpring(() => ({ x: 0 }))
  const [bgMoveRight, setBgMoveRight] = useState(false)
  const [bgMoveLeft, setBgMoveLeft] = useState(false)

  console.log(current)

  const next = (order, i) => {
    let nextSlide = order + 1
    setBgMoveRight(true)
    setTimeout(function() {
      setCurrent(nextSlide)
    }, 900)
    setTimeout(function() {
      setBgMoveRight(false)
    }, 1000)

    if (nextSlide > data.allSanitySlideDeck.edges.length) {
      nextSlide = 1
      setCurrent(nextSlide)
    }
  }
  const back = (order, i) => {
    let backSlide = order - 1
    setBgMoveLeft(true)
    setTimeout(function() {
      setCurrent(backSlide)
    }, 900)
    setTimeout(function() {
      setBgMoveLeft(false)
    }, 1000)

    if (backSlide < 1) {
      backSlide = data.allSanitySlideDeck.edges.length
      setTimeout(function() {
        setCurrent(backSlide)
      }, 900)
    }
  }

  const serializers = {
    types: {
      code: props => (
        <pre data-language={props.node.language}>
          <code>{props.node.code}</code>
        </pre>
      ),
    },
  }

  const data = useStaticQuery(graphql`
    query SlideQuery2 {
      allSanitySlideDeckStory(sort: { fields: order }) {
        edges {
          node {
            _rawPostContent(resolveReferences: { maxDepth: 10 })
            postTitle
            order
            postImage {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <SliderWrapper>
        <Slider>
          {data.allSanitySlideDeckStory.edges.map(({ node: slide }, i) => (
            <div>
            {i === 0 ? (
              <TitlePage key={i} bg={slide.postImage.asset.url}>
                <h1>{slide.postTitle}</h1>
              </TitlePage>
            ):(

            <Bg className="bg" key={i} background={slide.postImage.asset.url}>
              <div className="center">
                <div>

                <h1>{slide.postTitle}</h1>
                {slide._rawPostContent && (
                  <BlockContent
                  blocks={slide._rawPostContent}
                  serializers={serializers}
                  />
                )}
                </div>
                <img src={slide.postImage.asset.url}/>
                {/* <button>{item.button}</button> */}
              </div>
            </Bg>
            )}
            </div>
          ))}
        </Slider>
      </SliderWrapper>
    </>
  )
}

export default SlideStory
