import React from 'react'
import Layout from "../components/layout"
import SlideStory from "../components/slideStory"


const GingerBreadMan = ({ data }) => {
    return (
        <Layout>
      <SlideStory />
      {/* <Iframer dangerouslySetInnerHTML={ iframe1() } /> */}
    </Layout>
    )
}

export default GingerBreadMan